/* You can add global styles to this file, and also import other style files */
@import "styles/variables";
@import 'animate.css';
@import 'styles/index';

@tailwind base;
@tailwind components;
@tailwind utilities;


::-webkit-scrollbar {
  background-color: var(--white-100);
  height: 10px;
  width: 10px;
}

::-webkit-scrollbar-track {
  background-color: var(--white-100);
}

::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 16px;
  border: 2px solid #fff;
}

::-webkit-scrollbar-button {
  display: none;
}

html, body {
  height: 100%;
  touch-action: manipulation;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: Roboto, sans-serif;
  font-style: normal;
}


.splitter {
  &-vertical {
    width: 1px;
    height: 100%;
    background: var(--makktina-2-200);
  }

  &-horizontal {
    width: 100%;
    height: 1px;
    background: var(--makktina-2-200);
  }
}

.toast-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 10px;
  transition: 0.3s;
}

.read-more {
  cursor: pointer;
}

.content-description p {
  margin: 14px 0;
}

.custom-modal {
  .mat-mdc-dialog-surface.mdc-dialog__surface {
    background: transparent;
    box-shadow: none;
    overflow: visible;
  }
}


.submenu-overlay-container {
  bottom: unset !important;
  top: 72px !important;
}

.section {
  &-container {
    //padding: 120px 240px;
    position: relative;
  }
}

.container {
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
}

@media screen and (max-width: 500px) {
  .container {
    padding: 0 20px;
  }
}

.radial-gradient {
  background: repeating-radial-gradient(
      #FEECF7,
      black 5px,
      white 5px,
      white 10px
  );
}

.modal-open {
  overflow-y: unset;
  @media screen and (max-width: 768px){
    overflow-y: hidden;
  }
}


@media screen and (max-width: 425px) {
  .menu-list {
    left: 30px;
  }
}

mat-label {
  display: flex;
  align-items: center;
  gap: 6px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: var(--black-700);
  margin-bottom: 4px;
  letter-spacing: normal;

  .icon {
    background: var(--black-400);
  }
}

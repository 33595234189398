input.mat-mdc-form-field-input-control.mat-mdc-form-field-input-control {
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: normal;

  &::placeholder {
    color: var(--black-400) !important;
  }
}
.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input {
  color: var(--slate-900);
}

.search-input {
  display: flex;
  align-items: center;
  width: 380px;
  height: 38px;
  position: relative;

  input {
    border-width: 1px 0 1px 1px;
    border-style: solid;
    border-color: var(--black-300);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    border-radius: 6px 0 0 6px;
    height: 100%;
    width: 100%;
    padding: 9px 13px 9px 40px;
    background: var(--white-100);
    outline: none;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;

    &::placeholder {
      color: var(--black-400);
    }

    &:focus {
      border-color: var(--makktina-1-500);
      box-shadow: 0 0 0 2px #FDE4F3, 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.06);
    }

    &:hover {
      border-color: var(--makktina-1-500);
    }
  }

  &-icon {
    position: absolute;
    left: 13px;
  }

  &-btn {
    min-width: 80px;
    max-width: 100px;
    height: 38px;
    background-color: var(--makktina-2-50) !important;
    border: 1px solid var(--makktina-2-200);
    border-radius: 0 6px 6px 0;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: var(--makktina-2-600);
    transition: 0.3s all ease-in-out;

    &:hover {
      opacity: 0.8;
    }
  }
}

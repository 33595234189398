.mat-mdc-select {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: normal;
}

.mat-mdc-select-placeholder {
  color: var(--black-400);
}

.mat-mdc-select-value {
  color: var(--black-900);
}

div.mat-mdc-select-arrow {
  width: 16px;
  color: var(--black-400);
}

div.mat-mdc-select-arrow-wrapper {
  position: relative;
  width: 20px;
  height: 20px;
  display: inline-block;
  background-color: var(--black-400);
  -webkit-mask-size: contain !important;
  mask-size: contain !important;
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask: url('~/assets/images/icons/mini/chevron-down.svg') no-repeat 50% 50%;
  mask: url('~/assets/images/icons/mini/chevron-down.svg') no-repeat 50% 50%;

  .mat-mdc-select-arrow {
    display: none;
  }
}

div.mdc-menu-surface.mat-mdc-select-panel {
  margin-top: 4px;
  padding: 4px 0;
}

.mat-mdc-option.mat-mdc-option-active,
.mat-mdc-option.mdc-list-item--selected:not(.mat-mdc-option-multiple):not(.mdc-list-item--disabled) {
  background: transparent;
}

.mat-mdc-option:focus:not(.mdc-list-item--disabled),
.mat-mdc-option.mat-mdc-option-active {
  background: var(--black-500);
}

mat-option.mat-mdc-option {
  min-height: unset;
  padding: 8px 12px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: normal;

  .mat-pseudo-checkbox-full {
    margin-right: 6px;
  }
}

.mdc-list-item__primary-text {
  color: var(--slate-900);
}

.mat-mdc-option:hover:not(.mdc-list-item--disabled) {
  background: var(--makktina-2-600) !important;

  .mdc-list-item__primary-text,
  .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full ~ .mdc-list-item__primary-text {
    color: var(--white-100) !important;
  }
}

.mat-primary {
  .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #0F172A;
  }

  .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
  .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
    background: var(--makktina-2-300);

    & ~ .mdc-list-item__primary-text {
      color: var(--indigo-600) !important;
    }
  }
}

mat-pseudo-checkbox {
  &.mat-pseudo-checkbox {
    width: 16px;
    height: 16px;
    border-radius: 4px;

    &.mat-pseudo-checkbox-minimal.mat-pseudo-checkbox-checked::after {
      width: 12px;
      height: 5px;
      color: var(--makktina-2-300);
    }
  }

  &.mat-pseudo-checkbox-full {
    border: 1px solid;
    color: var(--slate-400);
  }
}

.mat-selected-count {
  display: flex;
  align-items: center;
  position: absolute;
  right: -10px;
  top: 0;
  height: 100%;
  background-color: var(--white-100);
  padding: 0 36px 0 6px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: var(--slate-400);
}

.with-chip {
  div.mat-mdc-select-arrow-wrapper {
    min-height: 34px;
  }

  .mat-mdc-select-placeholder {
    padding-left: 11px;
  }
}
